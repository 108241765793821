module.exports = {
    headline: "Panasonic ProPartner",
    subheadline: "Welcome back",
    inputs: [
        {
            label: "Username",
            id: "username",
            name: "username",
            placeholder: "Username",
            type: "username"
        },
        {
            label: "Password",
            id: "password",
            name: "password",
            password_placeholder: "Password",
            type: "password"
        }
    ],
    btn: "Sign in",
    partner: {
        label: "Not yet a ProPartner?",
        register: "Request registration"
    }
}