import React from 'react'
import i18 from "../lib/i18n";
import { Link } from "react-router-dom";
import Header from "../components/Header";

const t = i18.namespace("404");

const NoMatch404 = () => {

  return (
      <>
      <Header/>
      <div className="no-match-404">
        <div className="container">
          <div className="page_header">
            <h1 className="no-match-404_headline">{t("headline")}</h1>
          </div>

          <div className="no-match-404_wrapper">
            <div className="no-match-404_content">
              <div className="no-match-404_icon" />
              <p className="no-match-404_subheadline">{t("subheadline")}</p>
              <p className="no-match-404_text">{t("text")}</p>
              <Link className="btn btn_line" to={t("link_href")}>{t("link")}</Link>
            </div>
          </div>
        </div>
      </div>
      <div className={"footer"} />
      </>
  )
}

export default NoMatch404;
