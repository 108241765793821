import React, {useState} from 'react';
import { Auth } from 'aws-amplify';
import {FormContainer, FormSection, FormRow} from "../../components/layout/form";
import {LoadingSpinnerButton} from "../../components/layout/buttons";
import Header from '../../components/Header';
import i18 from "../../lib/i18n";

const t = i18.namespace("Register");

export default function Register()  {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [success, setSuccess] = useState("");

    async function handleSignUp() {
        try {
            const { user } = await Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    email: email,
                    name: name,
                    phone_number: phone,
    //                'custom:store': 'a2w_denmark',
                }
            });
            console.log(user);
            setSuccess(t("success") + email)
        } catch (error) {
            console.log('error signing up:', error);
            alert(error.message)
        }
    }

    return (
        <>
            <Header />
            <div className={"page"}>
                <div className={"registration checkout-form"}>
                    <div className={"container checkout_page"}>
                        <div className={"form-section checkout_page_content"}>
                            <h3 className={"checkout_page_subheadline"}>{t("subheadline")}</h3>
                            <p>{t("text")}</p>
                            <div className={"form-container"}>
                                <div className={"registration_step_content"}>
                                    <FormSection className="checkout_page_content">
                                        <FormContainer>
                                            <FormRow>
                                                <div className={"form-group col-12 col-lg-6"}>
                                                    <label className={"form-label"}
                                                           htmlFor={"email"}>
                                                        {t("email")}
                                                    </label>
                                                    <input className="form-control"
                                                           type="text"
                                                           placeholder=""
                                                           id="email"
                                                           name="email"
                                                           onChange={(event => setEmail(event.target.value))}
                                                    />
                                                </div>
                                            </FormRow>
                                            <FormRow>
                                                <div className={"form-group col-12 col-lg-6"}>
                                                    <label className={"form-label"}
                                                           htmlFor={"email"}>
                                                        {t("password")}
                                                    </label>
                                                    <input className="form-control"
                                                           type="password"
                                                           placeholder=""
                                                           id="password"
                                                           name="password"
                                                           onChange={(event => setPassword(event.target.value))}
                                                    />
                                                </div>
                                            </FormRow>
                                            <FormRow>
                                                <div className={"form-group col-12 col-lg-6"}>
                                                    <label className={"form-label"}
                                                           htmlFor={"name"}>
                                                        {t("name")}
                                                    </label>
                                                    <input className="form-control"
                                                           type="text"
                                                           placeholder=""
                                                           id="name"
                                                           name="name"
                                                           onChange={(event => setName(event.target.value))}
                                                    />
                                                </div>
                                            </FormRow>
                                            <FormRow>
                                                <div className={"form-group col-12 col-lg-6"}>
                                                    <label className={"form-label"}
                                                           htmlFor={"phoneNumber"}>
                                                        {t("phoneNumber")}
                                                    </label>
                                                    <input className="form-control"
                                                           type="text"
                                                           placeholder=""
                                                           id="phoneNumber"
                                                           name="phoneNumber"
                                                           onChange={(event => setPhone(event.target.value))}
                                                    />
                                                </div>
                                            </FormRow>
                                            <FormRow>
                                                <LoadingSpinnerButton
                                                    onClick={handleSignUp}
                                                    text={t("btn")}
                                                    classNames="btn-primary order-1 order-md-2 loading-spinner-button btn"
                                                    isEnabled={true}
                                                    isLoading={false}
                                                />
                                            </FormRow>

                                            <div className={"message"}>
                                                {success}
                                            </div>
                                        </FormContainer>
                                    </FormSection>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"footer"}/>
        </>
    );

}