    module.exports = {
    headline: "Contract Selection",
    unconfirmedUsers: "Unconfirmed Users",
    allUsers: "All Users",
    allPartners: "All Partners",
    id: "ID",
    email: "Email",
    name: "Name",
    phone: "Phone",
    status: "Status",
    action: "Action",
    confirm: "confirm"
};
