// sessionStorage.js

export const AUTH_TOKEN_SESSION_STORAGE = 'auth-token';
export const USER_INFO_SESSION_STORAGE = 'user-info';


/*
* write value to datafield.
*
* @name: string
* @value: string
* */
export const setSessionStorageItem = (name, value) => {
    window.sessionStorage.setItem(name,value)
}

/*
* get value by key form datafield
*
* @name: string
* @return string | null
* */
export const getSessionStorageItem = (name) => {
    return window.sessionStorage.getItem(name)
}

/*
* remove value by key
*
* @name: string
* */
export const removeSessionStorageItem = (name) => {
    window.sessionStorage.removeItem(name)
}

/*
* clear all values
* */
export const clearSessionStorage = () => {
    window.sessionStorage.clear()
}

/*
* remove value by key
*
* @name: string
* */
export const removeSessionStorageByName = (name) => {
    window.sessionStorage.removeItem(name)
}