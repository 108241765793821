import React from 'react'

import { useSortBy, useTable } from 'react-table'



function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )

  // Render the UI for your table
  return (
    <table {...getTableProps()} className={"users"}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} className={"headrow"}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                <span>
                {column.isSorted ? column.isSortedDesc ? ' ⇊' : ' ⇈': ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export function ReportTable(props) {
  const {data, showInvites} = props
  const inviteClolumns = React.useMemo(
    () => [
          {
            Header: 'Partner',
            accessor: 'partner',
          },
          {
            Header: 'Invitation Date',
            accessor: 'invitationDate',
          },
          {
            Header: 'Customer E-Mail',
            accessor: 'customerEmail',
          },
          {
            Header: 'Customer First Name',
            accessor: 'firstName',
          },
          {
            Header: 'Customer Last Name',
            accessor: 'lastName',
          },
          {
            Header: 'State',
            accessor: 'state',
          },
        ],
    []
  )

  const contractColumns = React.useMemo(
    () => [
          {
            Header: 'Partner',
            accessor: 'partner',
          },
          {
            Header: 'Invitation Date',
            accessor: 'invitationDate',
          },
          {
            Header: 'Purchase Date',
            accessor: 'purchaseDate',
          },
          {
            Header: 'Customer E-Mail',
            accessor: 'customerEmail',
          },
          {
            Header: 'Customer First Name',
            accessor: 'firstName',
          },
          {
            Header: 'Customer Last Name',
            accessor: 'lastName',
          },
          {
            Header: 'Contract',
            accessor: 'contract',
          },
          /*{ // CBDWA-573 hide kickback column
            Header: 'Kickback',
            accessor: 'kickbackString',
          },*/
          {
            Header: 'State',
            accessor: 'state',
          },
        ],
    []
  )

 //const data = React.useMemo(() => [{firstName:"first", lastName:"last", partner: "test", invitationDate: "2022.02.02", contract: "bronze", purchaseDate: "", kickback:"", state:"state"},
 //{firstName:"ffirst", lastName:"llast", partner: "best", invitationDate: "2023.02.02", contract: "bronze", purchaseDate: "", kickback:"", state:"state"}], [])

  return (
      <Table columns={showInvites? inviteClolumns : contractColumns} data={data} />
  )
}
