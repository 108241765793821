module.exports = {
    logo: {
        icon: "/image/logo/panasonic.svg",
        href: "/",
        alt: "Panasonic",
        title: "Panasonic",
        name: "Panasonic",
        aria_label: "Panasonic"
    },
    logout: "Logout",
    greeting: "Hi, {{name}}",
    links: [
        {
            text: "SERVICE PACKAGES",
            href: "/en_IE/home/service_package",
            linkType: "",
            dropdown: [
                {
                    text: "PACKAGES OVERVIEW",
                    href: "plans_overview",
                    linkType: ""
                },
                {
                    text: "PRODUCT FINDER ",
                    href: "product_finder",
                    linkType: ""
                }
            ]
        },
        {
            text: "SERVICE CONTACT",
            href: "https://www.aircon.panasonic.eu/IE_en/my-project/",
            linkType: "blank",
        },
    ],
    language: {
        flag: "/image/menu/locales/ireland.png",
        title: "/image/menu/heating_cooling.png"
    },
    btn: {
        text: "MY ACCOUNT",
        href: "/en_IE/home/my_account",
        linkType: "btn",
    },
    cart: {
        icon: "",
        href: "/en_IE/home/cart",
        linkType: "cart",
    }
};
