import React, {useState} from 'react';
import {FormRow, FormContainer, FormItem} from "../layout/form";
import {StandardButton} from "../../components/layout/buttons";
import {createCart} from "../../lib/dataAccess/services/cart"
import {notifyCustomerCartCreation} from "../../lib/dataAccess/services/customer"
import i18 from "../../lib/i18n";
import {

    AUTH_TOKEN_SESSION_STORAGE,
    USER_INFO_SESSION_STORAGE,
    getSessionStorageItem,
    removeSessionStorageByName
} from "../../lib/sessionStorage";
import {useHistory} from "react-router-dom";
import { getStore } from '../util/storeUtil';

const t = i18.namespace("RegisterCustomer");

export function ProductSelection(props) {

    const {products, customer, partnerInfo} = props;
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [internetFee, setInternetFee] = useState(false);
    const [cartCreated, setCartCreated] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");

    const history = useHistory();
    const userInfo = JSON.parse(getSessionStorageItem(USER_INFO_SESSION_STORAGE));


    const createCustomerCart = () => {
        const payload = {
            "sku": selectedProduct,
            "customerId": customer.id,
            "customerEmail": customer.email,
            "installedBaseUnitId": customer.installedBaseUnits[0]?.id,
            "addInstallationFee": internetFee,
            "storeKey": getStore(userInfo.country),
            "partnerInfo": partnerInfo
        }

        createCart(payload)
            .then(response => {
                setCartCreated(response);
            })
    }

    function logout() {
        removeSessionStorageByName(AUTH_TOKEN_SESSION_STORAGE);
        removeSessionStorageByName(USER_INFO_SESSION_STORAGE);
        history.push("/login");
    }

    const notifyCustomer = () => {
        notifyCustomerCartCreation(customer.id).then(response => {
            setSuccessMessage(<div className={"success"}>
                <p>{t("customerNotified") + customer.email}</p>
                <a className={"btn-primary"} href={"/partner"}>{t("createUser")}</a>
                <StandardButton
                        text={t("logout")}
                        onClick={logout}
                        isEnabled="true"
                        classNames={"btn btn_transparent success_logout"}
                    />
            </div>)
        } )
    }


    const saveProductSelection = (event) => {
        setInternetFee(event.target.value && !event.target.value.includes('bronze'));
        setSelectedProduct(event.target.value);
    }

    const getProductSelection = () =>{
        const options = [];
        console.log("products",products);
        products.sort((a,b) => a?.price?.amount - b?.price?.amount);
        console.log("products after sort",products);
        for(let product in products){
            if(customer.installedBaseUnits[0].internetReady || products[product].name === 'Comfort'){
                for(let v in products[product].variants){
                    let variant = products[product].variants[v];
                    options.push({value: variant.sku,
                        label: products[product].name
                        + " - " + variant.paymentPeriod
                        + " - " + variant.price.amount
                        + " "+ variant.price.currency, 
                        group: product},
                        );
                }
            }
        }
        const item = {
            label: "Select contract:",
            name: "contract",
            type: "radio-button-list",
            validators: [],
            width: "col",
            options: options
         };
         
        return <FormItem item={item} saveValue={saveProductSelection}/>
    }

    const showCartItems = () => {
        let items = [];
        for(let item in cartCreated.lineItems){
            items.push(<>{cartCreated.lineItems[item].name + ", " +
            cartCreated.lineItems[item].totalGross.currency + " " +
            cartCreated.lineItems[item].totalGross.amount} <br/></>)
        }
        return items;
    }

    return <>
        {
            !successMessage &&
            <FormContainer>
                <p className={"info"}>{t("customerText")} {customer.email}</p>

                {getProductSelection()}
                { customer.installedBaseUnits[0].internetReady && !customer.installedBaseUnits[0].internetConnected &&
                    <div className={"form-check"} id={"fee"}>
                        <input
                            className="form-check-input"
                            id={"internet-connection-fee"}
                            type="checkbox"
                            name={"Internet connection fee"}
                            disabled={selectedProduct && !selectedProduct.includes('bronze')}
                            checked={internetFee}
                            onChange={event => setInternetFee(event.target.checked)}
                        />
                        <label className="form-check-label" htmlFor={"Internet connection fee"}>
                            {t("internetFee")}
                        </label>
                    </div>
                }
                <FormRow>
                    <StandardButton
                        text={t("createCart")}
                        onClick={createCustomerCart}
                        isEnabled="true"
                        classNames={"btn-primary order-1 order-md-2 btn"}
                    />
                </FormRow>
            </FormContainer>
        }
    {
        cartCreated &&
        <FormContainer>
            <FormRow>
                <div className={"prepared-order"}>
                    <p>
                        {t("itemsInCustomerCart") +":"} <br/>
                        {showCartItems()}
                    </p>
                    {
                        !successMessage &&
                        <StandardButton
                            text={t("notifyCustomer")}
                            onClick={notifyCustomer}
                            isEnabled="true"
                            classNames={"btn-primary order-1 order-md-2 btn"}
                        />
                    }
                    {successMessage}
                </div>
            </FormRow>
        </FormContainer>
    }
    </>
}