import React, { useEffect, useState } from 'react';
import { fetchReportByPartner } from '../../lib/dataAccess/services/report'
import { ReportTable } from '../../components/reports/ReportTable'
import Header from '../../components/Header';
import { filterMonthInput, filterYearInput, filterTypeInput } from "../../components/reports/inputs"
import { FormContainer, FormSection, FormRow, FormItem } from "../../components/layout/form";
import { getSessionStorageItem, USER_INFO_SESSION_STORAGE } from '../../lib/sessionStorage';

export default function Report()  {

    const [orders, setOrders] = useState([]);
    const [invites, setInvites] = useState([]);
    const [numberOfOrders, setNumberOfOrders] = useState(0);
    const [numberOfInvites, setNumberOfInvites] = useState(0);
    const [totalKickback, setTotalKickback] = useState("");
    const [filterMonth, setFilterMonth] = useState("")
    const [filterYear, setFilterYear] = useState("")
    const [filterType, setFilterType] = useState("")
    const [runningQuery, setRunnginQuery] = useState("--")

    const userInfo = JSON.parse(getSessionStorageItem(USER_INFO_SESSION_STORAGE));

    useEffect(() => {
        function currentQuery() {
            return filterMonth + "-" + filterYear + "-" + filterType
        }
        function isValidQuery() {
            return filterMonth !== "" && filterYear !== "" && filterType !== ""
        }

        console.log("use effect: " + currentQuery())

        // Fetch data only if:   
        // userInfo is set
        // all three parameter (filterMonth, filterYear, filterType) are set
        // and query has changed since last execution
        if(userInfo && isValidQuery() && runningQuery !== currentQuery()) {
            fetchReportByPartner(userInfo.id, filterMonth, filterYear, filterType)
            .then((result) => {
                setRunnginQuery(filterMonth + "-" + filterYear + "-" + filterType)
                console.log("send request")
                let customersWithOrder = [];
                let customersWithoutOrder = [];
                let customersWithOrderCount = 0;
                let customersWithoutOrderCount = 0;
                let totalKickbackValue = result.totalKickbackValue;
                let totalKickbackCurrency = result.totalKickbackCurrency ? result.totalKickbackCurrency : "";

                result.customersWithOrder.forEach(customer => {
                    customersWithOrder.push(customer);
                    customersWithOrderCount++;
                })
                result.customersWithoutOrder.forEach(customer => {
                    customersWithoutOrder.push(customer);
                    customersWithoutOrderCount++
                })
                
                setOrders(customersWithOrder)
                setInvites(customersWithoutOrder)
                setNumberOfInvites(customersWithoutOrderCount)
                setNumberOfOrders(customersWithOrderCount)
                setTotalKickback(totalKickbackValue > 0 ? totalKickbackValue + " "+ totalKickbackCurrency : "0")
                console.log("end send request")
            })
            .catch((err) => {
                console.error("Error while fetching data from backend: ", err)
                setRunnginQuery(currentQuery())
            })
            .finally(() => {
                console.log("finally")
                setRunnginQuery(currentQuery())
            });
        }

    },[userInfo, filterMonth, filterYear, filterType, runningQuery])


    function saveFilterMonth(event) {
        setFilterMonth(event.target.value)
    }
    function saveFilterYear(event) {
        setFilterYear(event.target.value)
    }
    function saveFilterType(event) {
        setFilterType(event.target.value)
    }


    return (
        <>
            <Header/>
            <h2>Report</h2>
            <FormSection className="checkout_page_content">
                <FormContainer>
                    <FormRow>
                        <FormItem
                            item = {filterMonthInput}
                            saveValue = {saveFilterMonth}
                        />
                        <FormItem
                            item = {filterYearInput()}
                            saveValue = {saveFilterYear}
                        />
                        <FormItem
                            item = {filterTypeInput}
                            saveValue = {saveFilterType}
                        />
                    </FormRow>
                </FormContainer>
                <div className="form-container">
                    <h4>Invites ({numberOfInvites}):</h4>
                    <ReportTable data={invites} showInvites={true}/>
                </div>
                <br/>
                <div className="form-container">
                    <h4>Contracts: ({numberOfOrders})</h4>
                    <ReportTable data={orders}/>
                    <div className="total"
                         style={{display: "none"}}>
                        Kickback Total: {totalKickback}
                    </div>
                </div>
            </FormSection>
            <div className={"footer"}/>
        </>
    );

}