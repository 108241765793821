module.exports = {
    headline: "On-behalf customer invitation",
    customerInvitationText: "Enter customer registration data.",
    customerText: "Customer: ",
    backBtn: "back",
    internetErrorText: "When device is connected to the internet it has to be internet ready.",
    customerAlreadyRegistered: "Registration failed: This customer has already been registered.",
    internalErrorText: "An unexpected error occurred, please try again later.",
    internetFee: "add internet connection fee to cart",
    notifyCustomer: "notify customer",
    itemsInCustomerCart: "items in customer cart",
    createCart: "create cart",
    customerNotified: "Customer with this email was notified: ",
    createUser: "create another user",
    logout: "logout",
    phoneNumber: "phone number",
}