import axios from 'axios';
import { getDataAccessHeaders, logout } from '../dataAccessHelper';


export async function fetchAdminReportByPartner(partner, filterMonth, filterYear, filterType) {
    const result = await axios.get(`${process.env.REACT_APP_MIDDLEWARE_HOST}/admin-report/${partner}?filterMonth=${filterMonth}&filterYear=${filterYear}&filterType=${filterType}`, {
        headers: getDataAccessHeaders()
    })
    .catch(err => {
        if (err.status >= 401) {
          logout();
        }
    });
    return result.data
};

export async function fetchReportAllPartners(country, filterMonth, filterYear, filterType) {
    const result = await axios.get(`${process.env.REACT_APP_MIDDLEWARE_HOST}/admin-report?country=${country}&filterMonth=${filterMonth}&filterYear=${filterYear}&filterType=${filterType}`, {
        headers: getDataAccessHeaders()
    })
    .catch(err => {
        if (err.status >= 401) {
          logout();
        }
    });
    return result.data
};

export async function fetchReportByPartner(partner, filterMonth, filterYear, filterType) {
    const result = await axios.get(`${process.env.REACT_APP_MIDDLEWARE_HOST}/report/${partner}?filterMonth=${filterMonth}&filterYear=${filterYear}&filterType=${filterType}`, {
        headers: getDataAccessHeaders()
    })
    .catch(err => {
        if (err.status >= 401) {
          logout();
        }
    });
    return result.data
};