import axios from 'axios';
import { getDataAccessHeadersNoAuth } from '../dataAccessHelper';


export async function getAllProducts(storeKey) {
    const result = await axios.get(`${process.env.REACT_APP_PRODUCT_SERVICE_HOST}/product`, {
        params: { storeKey: storeKey },
        headers: getDataAccessHeadersNoAuth()
    });
    return result.data
};