import React, {useState} from 'react';
import { Auth } from 'aws-amplify';
import {requiredInput} from "../../lib/validation";
import { getSessionStorageItem, setSessionStorageItem, AUTH_TOKEN_SESSION_STORAGE, USER_INFO_SESSION_STORAGE } from '../../lib/sessionStorage';
import {useHistory} from 'react-router-dom';
import i18 from "../../lib/i18n";
import {LoadingSpinnerButton} from "../../components/layout/buttons";
import Header from '../../components/Header';


const t = i18.namespace("LoginPage");
const messages = i18.namespace("Validation");

export default function Login()  {

    const history = useHistory();

    const [user, setUser] = useState("");
    const [newPasswordRequired, setNewPasswordRequired] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        username: { error: false, value: "" },
        password: { error: false, value: "" },
        login: { error: false, value: "" },
        missingEmail: { error: false, value: "" },
    });

    const _onBlur = (event, type) => {
        let value = event.target.value;
        let error = state[type].error;
        if (type === "password") {
            error = requiredInput(value);
            updateState(type, value, error);
        }

        if (type === "username") {
            updateState(type, value, error);
        }
    };

    const updateState = (type, value, error) => {
        let temp = state[type];
        temp.error = error;
        temp.value = value;

        setState((prevState) => {
            return {...prevState, [type]: temp};
        });
    };

    const handleLogin = (event) => {
        event.preventDefault();
        setIsLoading(true);

        Auth.signIn(state.username.value, state.password.value)
            .then(cognitoUser => {
                setUser(cognitoUser);
                setSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE, cognitoUser?.signInUserSession?.accessToken?.jwtToken);
                let country  = cognitoUser.attributes['custom:country'] || "DK";
                let userInfo = {
                    id : cognitoUser.username,
                    email: cognitoUser.attributes.email,
                    name: cognitoUser.attributes.name,
                    phone: cognitoUser.attributes.phone,
                    groups: cognitoUser.signInUserSession.accessToken.payload['cognito:groups'],
                    country: country
                }
                setSessionStorageItem(USER_INFO_SESSION_STORAGE, JSON.stringify(userInfo));
                if(cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED'){
                    setNewPasswordRequired(true);
                } else{
                    history.push('/home');
                }
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            });

        setIsLoading(false);
    }

    const handlePasswordChallenge = (event) => {
        event.preventDefault();
        Auth.completeNewPassword(user, newPassword, {email: state.email.value, name: state.email.value})
            .then(cognitoUser => {
                setNewPasswordRequired(false);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const showErrorMessage = (type) => {
        return (
            <p className="error_message error_message--red">
                {messages("validationMethods")[type].message}
            </p>
        );
    };

    const getLoginForm = () => {
        return <>
            {t("inputs").map((item, i) => (
                <div className="login-page_inputs" key={i}>
                    <p className="login-page_inputs_label">{item.label}</p>
                    <input
                        className={
                            state[item.type].error
                                ? "login-page_inputs_input error"
                                : "login-page_inputs_input"
                        }
                        type={item.type}
                        name={item.name}
                        id={item.id}
                        onChange={(event) => _onBlur(event, item.type)}
                        // onChange={(event) => setValue(event.target.value, item.type)}
                    />
                    {state[item.type].error && showErrorMessage(item.type)}
                </div>))}

            <LoadingSpinnerButton
                onClick={handleLogin}
                text={t("btn")}
                classNames="btn-primary order-1 order-md-2 loading-spinner-button btn"
                isEnabled={true}
                isLoading={isLoading}
            />

{/*
            <div className={"login-page_protection-plan partner-registration"}>
                <p className={"label"}>{t("partner.label")}</p>
                <Link
                    className="btn btn_transparent"
                    to="/register">
                    {t("partner.register")}
                </Link>
            </div>
 */}
         </>
    }

    const redirectToHome = () => {
        history.push('/home');
    }

    const getPasswordChallengeForm = () => {
        return <>
            <div>
                <input
                    type="password"
                    name="New Password"
                    id="newPassword"
                    placeholder="New Password"
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <button
                    type="button"
                    onClick={handlePasswordChallenge}
                >
                    Set new Password
                </button>
            </div>
        </>
    }

    return (
        <>
            <Header/>
            <div className="login-page">
                <div className="page_header container">
                    <h1 className="login-page_headline">{t("headline")}</h1>
                </div>

                <div className="login-page_wrapper container">
                    <div className="login-page_content">
                        <h3 className="login-page_subheadline">{t("subheadline")}</h3>

                        {getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE) ? redirectToHome() : getLoginForm()}
                        {newPasswordRequired ? getPasswordChallengeForm() : <></>}
                    </div>
                </div>
            </div>
            <div className={"footer"} />
        </>
    );

}