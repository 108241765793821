import React from "react";
import i18 from "../lib/i18n";
import {Link} from 'react-router-dom';
import { logout } from "../lib/dataAccess/dataAccessHelper";
import {Logout} from "./logout";

const t = i18.namespace("Header");

const Header = () => {
    return (
        <>
        <div className="header">
            <Link to="/home">
                <img src={t("logo.path")} alt={t("logo.alt")}/>
            </Link>
            <Logout handleClick={() => logout()}/>
        </div>
        </>
    )
}
export default Header;