const awsConfig ={
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: 'eu-central-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT,

        oauth: {
            domain:  process.env.REACT_APP_COGNITO_ENDPOINT,
            scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
            redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
            redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
            responseType: "token"
          }
    }
};
export default awsConfig;
