import React, {useEffect, useRef, useState} from 'react';
import {getSessionStorageItem, USER_INFO_SESSION_STORAGE} from '../../lib/sessionStorage';
import {FormContainer, FormFieldset, FormGroup, FormItem, FormRow, FormSection} from "../../components/layout/form";
import {StandardButton} from "../../components/layout/buttons";
import {
    cityInput,
    deviceTypeInput,
    emailInput,
    firstNameInput,
    gateWayIdInput,
    installationDateInput,
    internetConnectedInput,
    internetReadyInput,
    lastNameInput,
    phoneInput,
    serialNumberInput,
    streetInput,
    zipCodeInput,
    // sendInvoice
} from "../../components/customerRegister/inputs"
import Header from '../../components/Header';
import {register} from '../../lib/dataAccess/services/customer';
import {getAllProducts} from '../../lib/dataAccess/services/product';
import {findArrayElement, getUpdatedValues} from '../../components/util/formUtil'
import {ProductSelection} from '../../components/customerRegister/ProductSelection'
import i18 from "../../lib/i18n";
import {getStore} from '../../components/util/storeUtil';


const t = i18.namespace("RegisterCustomer");

export default function RegisterCustomer()  {

    useEffect(() => {
    }, []);

    const [registerValues, setRegisterValues] = useState({values:[]});
    const [products, setProducts] = useState([]);
    const [customerCreated, setCustomerCreated] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [errorText, setErrorText] = useState("");
    const [showSomeMessages, setShowSomeMessages] = useState(false);
    const invalidFields = useRef({})

    const userInfo = JSON.parse(getSessionStorageItem(USER_INFO_SESSION_STORAGE));
    const partnerInfo = {
        partnerId: userInfo.id,
        partnerName: userInfo.name,
        partnerEmail: userInfo.email,
        partnerPhone: userInfo.phone,
        orgaId: "orgaId"
    };
    useEffect(() => {
        if(userInfo.country === 'ES'){
            const sendInvoiceValue = {target:{
                name: "sendInvoice",
                type: "checkbox",
                checked: false,
                label: "",
            }}
            getUpdatedValues(sendInvoiceValue, registerValues)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.country])

    const handleRegistration = () => {
        if(!enabled()){
            setShowSomeMessages(true)
            return
        }
        let address = {
            zipCode: findArrayElement(registerValues.values, "zipCode").value,
            city: findArrayElement(registerValues.values, "city").value,
            street: findArrayElement(registerValues.values, "street").value,
            phoneNumber: findArrayElement(registerValues.values, "phoneNumber")?.value,
            countryCode: userInfo.country
        };
        let baseUnit = {
            installationDate: findArrayElement(registerValues.values, "installationDate").value,
            deviceTye: findArrayElement(registerValues.values, "model").value,
            serialNumber: findArrayElement(registerValues.values, "serialNumber").value,
            gatewayId: findArrayElement(registerValues.values, "gatewayId").value,
            internetReady: findArrayElement(registerValues.values, "internetReady").value,
            internetConnected: findArrayElement(registerValues.values, "internetConnected").value,
            address: address
        }
        let registerData = {
            email: findArrayElement(registerValues.values, "email").value,
            firstName: findArrayElement(registerValues.values, "firstName").value,
            lastName: findArrayElement(registerValues.values, "lastName").value,
            address: address,
            storeKey:  getStore(userInfo.country),
            partnerInfo: partnerInfo,
            ...(userInfo.country === 'ES' && {sendInvoice: findArrayElement(registerValues.values, "sendInvoice").value}),
            installedBaseUnits: [baseUnit]
        }
        register(registerData).then((registeredCustomer) => {
            getAllProducts( getStore(userInfo.country)).then(data => {
                setProducts(data.result);
                setCustomer(registeredCustomer);
                setCustomerCreated(true);
            })
            .catch(error => {setErrorText(t("internalError"))})
        })
        .catch(error => {setErrorText(t("customerAlreadyRegistered"))});
    }

    function saveInternetReady(event) {
        let newValues = getUpdatedValues(event, registerValues)
        if(event.target.value === "false"){
            const updateInternetConnected = {target:{
                name: "internetConnected",
                type: "radio",
                value: "false",
                label: "",

            }}
            newValues = getUpdatedValues(updateInternetConnected, {values: newValues})
        }
        setRegisterValues({values: newValues});
        enabled()
    }
    function saveInternetConnected(event) {
        let newValues = getUpdatedValues(event, registerValues)
        if(event.target.value === "true"){
            const updateInternetConnected = {target:{
                name: "internetReady",
                type: "radio",
                value: "true",
                label: "",

            }}
            newValues = getUpdatedValues(updateInternetConnected, {values: newValues})
        }
        setRegisterValues({values: newValues});
        enabled()
    }
    // function handleSendInvoice(e){
    //     let newValues = getUpdatedValues(e, registerValues)
    //     setRegisterValues({values: newValues});
    // }

    function saveRegistrationFormValue(event, invalid) {
        const newValues = getUpdatedValues(event, registerValues)
        invalidFields.current = {
            ...invalidFields.current,
            [event.target.name]: invalid
        }
        setRegisterValues({values: newValues});
        enabled()
    }

    function enabled() {
        ['internetReady', 'internetConnected'].forEach((fields) => {
            invalidFields.current[fields]= typeof findArrayElement(registerValues.values, fields) === 'undefined'
        })
        const allValid = Object.values(invalidFields.current).every(item => !item)
        if(!allValid) return false
        if (registerValues.values.length === 0) {
            return false
        }
        if (registerValues.values.slice(0,10).some(entry => entry.value === "")) {
            return false
        } else {
            return true
        }
    }
    return (
        <>
        <Header/>
        <div className={"page"}>
            <div className="page_header container">
                <h1 className="login-page_headline">{t("headline")}</h1>
            </div>

            <div className={"registration checkout-form"}>
                <div className={"container checkout_page"}>
                    <div className={"form-section checkout_page_content"}>
                        <div className={"form-container"}>
                            <div className={"registration_step_content"}>
                                {customerCreated === true && <ProductSelection
                                    products={products}
                                    customer={customer}
                                    partnerInfo={partnerInfo}/>}
                                {customerCreated === false && <>
                                <p className={"text"}>{t("customerInvitationText")}</p>
                                <FormSection className="checkout_page_content">
                                    <FormContainer>
                                        <FormRow>
                                            <FormItem
                                                item = {emailInput}
                                                saveValue = {saveRegistrationFormValue}
                                                showSomeMessages={showSomeMessages}
                                            />
                                        </FormRow>
                                        <FormRow>
                                            <FormItem
                                                item = {firstNameInput}
                                                saveValue = {saveRegistrationFormValue}
                                                showSomeMessages={showSomeMessages}
                                            />
                                            <FormItem
                                                item = {lastNameInput}
                                                saveValue = {saveRegistrationFormValue}
                                                showSomeMessages={showSomeMessages}
                                            />
                                        </FormRow>
                                        <FormRow>
                                            <FormItem
                                                item = {zipCodeInput[userInfo.country]}
                                                saveValue = {saveRegistrationFormValue}
                                                showSomeMessages={showSomeMessages}
                                            />
                                            <FormItem
                                                item = {cityInput}
                                                saveValue = {saveRegistrationFormValue}
                                                showSomeMessages={showSomeMessages}
                                            />
                                        </FormRow>
                                        <FormRow>
                                            <FormItem
                                                item = {streetInput}
                                                saveValue = {saveRegistrationFormValue}
                                                showSomeMessages={showSomeMessages}
                                            />
                                        </FormRow>
                                        <FormRow>                                       
                                             <FormItem
                                                item = {phoneInput[userInfo.country]}
                                                saveValue = {saveRegistrationFormValue}
                                                showSomeMessages={showSomeMessages}
                                                country={userInfo.country}
                                            />
                                        </FormRow>
                                        <FormRow>
                                            <FormItem
                                                item = {installationDateInput}
                                                saveValue = {saveRegistrationFormValue}
                                                showSomeMessages={showSomeMessages}
                                            />
                                        </FormRow>
                                        <FormRow>
                                            <FormItem
                                                item = {deviceTypeInput}
                                                saveValue = {saveRegistrationFormValue}
                                                showSomeMessages={showSomeMessages}
                                            />
                                            <FormItem
                                                item = {serialNumberInput}
                                                saveValue = {saveRegistrationFormValue}
                                                showSomeMessages={showSomeMessages}
                                            />
                                        </FormRow>

                                        <FormRow>
                                            <FormGroup label={internetConnectedInput.label} htmlFor={internetConnectedInput.name} className={internetConnectedInput.width}>
                                                <FormFieldset >
                                                    {internetConnectedInput.options.map((option, i) => {
                                                        return <div key={'internet-connected'+option.label} className={"form-check internet-connected-"+i}>
                                                            <input className={"form-check-input"}
                                                            id={internetConnectedInput.name + i}
                                                            type={"radio"}
                                                            name={internetConnectedInput.name}
                                                            value={option.value}
                                                            checked={registerValues.values && option.value === findArrayElement(registerValues.values, "internetConnected")?.value}
                                                            onChange={(event) => saveInternetConnected(event)}/>
                                                            <label className={"form-check-label"} htmlFor={internetConnectedInput.name + i}>
                                                                {option.label}
                                                            </label>
                                                        </div>})
                                                    }
                                                    </FormFieldset>
                                                    {internetConnectedInput.images.map((image, i)=>{
                                                        return <img key={'internetConnectedInput'+image} className="simple_checkbox_image" alt="" src={image}/>
                                                    })}
                                            </FormGroup>
                                        </FormRow>
                                        <FormRow>
                                            <FormGroup label={internetReadyInput.label} htmlFor={internetReadyInput.name} className={internetReadyInput.width}>
                                                <FormFieldset className={"internet-ready-selection"}>
                                                    <FormRow>
                                                        {internetReadyInput.options.map((option, i) => {
                                                            return <div key={'internet-ready-selection'+option.label} className={"form-check"}>
                                                                <input className={"form-check-input"}
                                                                       id={internetReadyInput.name + i}
                                                                       type={"radio"}
                                                                       name={internetReadyInput.name}
                                                                       value={option.value}
                                                                       checked={registerValues.values && option.value === findArrayElement(registerValues.values, "internetReady")?.value}
                                                                       onChange={(event) => saveInternetReady(event)}/>
                                                                <label className={"form-check-label"} htmlFor={internetReadyInput.name + i}>
                                                                    {option.label} <br/>
                                                                    <img className="simple_checkbox_image" alt="" src={internetReadyInput.images[i]}/>
                                                                </label>

                                                            </div>})
                                                        }
                                                    </FormRow>
                                                </FormFieldset>
                                            </FormGroup>
                                        </FormRow>

                                        <FormRow>
                                            <FormItem
                                                item = {gateWayIdInput}
                                                saveValue = {saveRegistrationFormValue}
                                                showSomeMessages={showSomeMessages}
                                            />
                                        </FormRow>
                                        {/* {userInfo.country === 'ES' && (
                                            <FormRow>
                                                <input
                                                    className="ml-2 mr-1"
                                                    id={sendInvoice[userInfo.country].name}
                                                    name={sendInvoice[userInfo.country].name}
                                                    type={sendInvoice[userInfo.country].type}
                                                    onChange={handleSendInvoice}
                                                />
                                                <label htmlFor={sendInvoice[userInfo.country].name}>
                                                    {sendInvoice[userInfo.country].label}
                                                </label>
                                            </FormRow>
                                        )}     */}

                                        <p className={"internet-setting-error-text"}>
                                            {errorText}
                                        </p>

                                        <FormRow>
                                            <StandardButton
                                                text="Submit"
                                                onClick={handleRegistration}
                                                isEnabled
                                                classNames={`btn-primary order-1 order-md-2 btn ${!enabled()? 'disabled' : ''}`}
                                            />
                                        </FormRow>
                                    </FormContainer>
                                </FormSection>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={"footer"}/>
        </>
    );

}