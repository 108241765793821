import React, { useEffect } from 'react';
import { Auth, Amplify } from 'aws-amplify';
import awsConfig from './aws-config';
import i18n from "./lib/i18n";

import NoMatch404 from "./pages/404";
import Home from './pages/Home'
import Login from './pages/guest/Login'
import Register from './pages/guest/Register'
import ListUsers from './pages/admin/ListUsers'
import Report from './pages/admin/Report'
import PartnerReport from './pages/partner/PartnerReport'
import RegisterCustomer from './pages/partner/RegisterCustomer'
import { Route, Switch } from 'react-router';
import PrivateRoute from './components/privateRoute';
import { getSessionStorageItem, setSessionStorageItem, AUTH_TOKEN_SESSION_STORAGE, USER_INFO_SESSION_STORAGE } from './lib/sessionStorage';
import{addPartnerToGroup} from './lib/dataAccess/services/userAccount'

Amplify.configure(awsConfig);

i18n.init('en');

function App() {

    useEffect(() =>{
        if(!getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE)){
            Auth.currentAuthenticatedUser()
            .then(cognitoUser => {
                if(!cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
                    .find(element => element === "b2b-partner" || element === "b2b-admin")) {
                            addPartnerToGroup(cognitoUser.username,  cognitoUser?.signInUserSession?.accessToken?.jwtToken).then(() => {
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }

                setSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE, cognitoUser?.signInUserSession?.accessToken?.jwtToken);
                let country  = cognitoUser.attributes['custom:country'] || "DK";
                let userInfo = {
                    id : cognitoUser.username,
                    email: cognitoUser.attributes.email,
                    name: cognitoUser.attributes.name,
                    phone: cognitoUser.attributes.phone,
                    country: country,
                    groups: cognitoUser.signInUserSession.accessToken.payload['cognito:groups'],
                }
                setSessionStorageItem(USER_INFO_SESSION_STORAGE, JSON.stringify(userInfo));
            }).catch(() => {
                if(window.location.search.indexOf("userId") > -1 &&  window.location.search.indexOf("companyId") > -1){
                    Auth.federatedSignIn({customProvider: "ProClub"});
                }
            });
        }
    })

    return (
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/home" component={Home}/>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/register" component={Register}/>
            <Route exact path="/404" component={NoMatch404}/>
            <PrivateRoute exact group="b2b-admin" path="/admin/list">
                <ListUsers/>
            </PrivateRoute>
            <PrivateRoute exact group="b2b-admin" path="/admin/report">
                <Report/>
            </PrivateRoute>
            <PrivateRoute exact path="/partner">
                <RegisterCustomer/>
            </PrivateRoute>
            <PrivateRoute exact path="/partner/report">
                <PartnerReport/>
            </PrivateRoute>
        </Switch>
    );
}

export default App;