import React from 'react';
import i18 from "../lib/i18n";
import { getSessionStorageItem, AUTH_TOKEN_SESSION_STORAGE} from '../lib/sessionStorage';

const t = i18.namespace("Menu");

const isLoggedIn = () => {
    return getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE);
}

export const Logout = (props) => {
    return (
        <>
            {isLoggedIn() && <a className="menu_top_logout" href={"#root"} type={"link"} onClick={props.handleClick}>{t("logout")}</a>}
        </>
    )
};

export default Logout;
