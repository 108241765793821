import React, { useEffect, useState } from 'react';
import{fetchAllUsers, fetchPartners} from '../../lib/dataAccess/services/userAccount'
import Header from "../../components/Header";
import i18 from "../../lib/i18n";

const t = i18.namespace("ListUsers");

export default function ListUsers()  {

//    const [unconfirmedUsers, setUnconfirmedUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        fetchAllUsers().then((users) => {
            setAllUsers(users);
        });
        fetchPartners().then((users) => {
            setPartners(users);
        });
    },[])


    const getUsers = (userList) =>{
        let users = []
        for(let i in userList){
            users.push(<tr>
                <td>{userList[i].id}</td>
                <td>{userList[i].email}</td>
                <td>{userList[i].name}</td>
                <td>{userList[i].phone}</td>
                <td>{userList[i].status}</td>
            </tr>)
        }
        return users
    }

    function drawTable(users, hasAction= false) {
        return <table className={"users"}>
            <tr className={"headrow"}>
                <td>{t("id")}</td>
                <td>{t("email")}</td>
                <td>{t("name")}</td>
                <td>{t("phone")}</td>
                <td>{t("status")}</td>
                {hasAction ? <td>{t("action")}</td> : ""}
            </tr>
            <tbody>{users}</tbody>
        </table>
    }

    return (
        <>
            <Header/>
            <div className={"page"}>
                <div className="page_header container">
                    <h1 className="login-page_headline">{t("headline")}</h1>
                </div>
            </div>

            <div className={"registration checkout-form"}>
                <div className={"container checkout_page"}>
                    <div className={"form-section checkout_page_content"}>
                        <div className={"form-section checkout_page_content"}>
                            <div className={"form-container"}>
                                <div className={"registration_step_content"}>
                                <h2>{t("allUsers")}</h2>
                                {drawTable(getUsers(allUsers))}
                                </div>
                            </div>
                        </div>

                        <div className={"form-section checkout_page_content"}>
                            <div className={"form-container"}>
                                <div className={"registration_step_content"}>
                                <h2>{t("allPartners")}</h2>
                                {drawTable(getUsers(partners))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"footer"}/>
        </>
    );

}