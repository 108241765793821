import React, { useEffect, useState, useCallback } from 'react';
import {Auth} from 'aws-amplify';
import awsConfig from '../aws-config';
import { useHistory } from 'react-router-dom';
import { Route } from 'react-router';

Auth.configure(awsConfig);

function PrivateRoute({ children, group, ...rest }) {

    const [auth, setAuth] = useState(false);
    const history = useHistory();

    const isAuthenticated = useCallback(() => {
        setAuth(false);

        const redirectToLogin = () => {
            history.push('/login');
        }

        Auth.currentSession().then( response => {
            const groups = response?.accessToken?.payload["cognito:groups"];
            if(response.isValid() && (typeof group === 'undefined' || groups.includes(group))) {
                setAuth(true);
            } else {
                redirectToLogin();
            }
        }).catch(() => {
            redirectToLogin();
        });
    }, [group, history])


    useEffect(() => {
        isAuthenticated();
    }, [isAuthenticated]);

    return (
        <Route {...rest}>
            { auth ? children : null }
        </Route>
    )
}

export default PrivateRoute;