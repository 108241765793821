module.exports = {
    headline: "Panasonic ProPartner",
    subheadline: "Home",
    login: "Login",
    register: "Register",
    admin: {
        listUsers: "List Users",
        report: "Report"
    },
    partner: {
        register: "Register Customer",
        report: "Report"
    }
}