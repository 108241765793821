import axios from 'axios';
import { getDataAccessHeaders, logout } from '../dataAccessHelper';

export async function createCart(createCartData) {
    const result = await axios.post(`${process.env.REACT_APP_MIDDLEWARE_HOST}/cart`, createCartData, {
        headers: getDataAccessHeaders()
    })
    .catch(err => {
        if (err.status >= 401) {
          logout();
        }
    });
    return result.data
};