import axios from 'axios';
import { getDataAccessHeaders, getDataAccessHeadersWithToken, logout } from '../dataAccessHelper';


export async function fetchUnconfirmedUsers() {
    const result = await axios.get(`${process.env.REACT_APP_MIDDLEWARE_HOST}/account/list-unconfirmed`, {
        headers: getDataAccessHeaders()
    })
    .catch(err => {
        if (err.status >= 401) {
          logout();
        }
    });
    return result.data
};

export async function fetchAllUsers() {
    const result = await axios.get(`${process.env.REACT_APP_MIDDLEWARE_HOST}/account/list`, {
        headers: getDataAccessHeaders()
    })
    .catch(err => {
        if (err.status >= 401) {
          logout();
        }
    });
    return result.data
};

export async function fetchPartners() {
    const result = await axios.get(`${process.env.REACT_APP_MIDDLEWARE_HOST}/account/list-partners`, {
        headers: getDataAccessHeaders()
    })
    .catch(err => {
        if (err.status >= 401) {
          logout();
        }
    });
    return result.data
};

export async function confirmUser(userId) {
    const result = await axios.post(`${process.env.REACT_APP_MIDDLEWARE_HOST}/account/confirm?user=${userId}`, null, {
        headers: getDataAccessHeaders()
    })
    .catch(err => {
        if (err.status >= 401) {
          logout();
        }
    });
    return result.data
};

export async function addPartnerToGroup(userId, token) {
    const result = await axios.post(`${process.env.REACT_APP_MIDDLEWARE_HOST}/partner/add-to-group?user=${userId}`, null, {
        headers: getDataAccessHeadersWithToken(token)
    })
    .catch(err => {
        if (err.status >= 401) {
          logout();
        }
    });
    return result.data
};