module.exports = {
    common: {
        REQUIRED: "This field is required",
        MAX_LENGTH: "Max {{maxLength}} characters",
        MAX_LENGTH_DIGIT: "Max {{maxLength}} digits",
        COMPARE: "The repeat value should be the same",
        DATE: "No valid date",
        MIN_LENGTH: "Min {{minLength}} characters",
        MIN_LENGTH_DIGIT: "Min {{minLength}} digits",
        EXACTLY_LENGTH: "Exact {{exactlyLength}} characters",
        EXACTLY_DIGITS_ONLY: "Must consist of exactly {{exactlyLength}} numerical digits",
        EXACTLY_MATCH_GATEWAYID_FORMAT: "Must match DeviceIds Format B9999-99999",
        DIGITS_ONLY: "only numerical digits are allowed",
        PHONE_PREFIX: "Please enter a valid country calling code",
        EMAIL: "No valid email address",
        BACKEND_VALIDATION_ERROR: "There is a server error",
        ZIPCODE: "{{length}} digits",
        SWEDISH_ZIPCODE: "3 digits space 2 digits",
        PASSWORD: "Min 8 characters, numbers and letters"
    },
    USER_ALREADY_REGISTERED: "We cannot proceed, as your e-mail address is already registered",

    validationMethods: {
        "required": {
            message: "This field is required.",
        },
        "email": {
            message: "Invalid email address",
        },
        "matching_email_values": {
            message_mail: "Invalid email address",
            message_not_same: "The email addresses must be the same."
        },
        "password": {
            message: "Invalid password",
            message_password: "Invalid password.",
            message_password_lengths: "Min 8 characters, numbers and letters",
            message_not_same: "The passwords must be the same."
        },
        "loginError": {
            message: "Login or Password are incorrect"
        },
        "missingEmail": {
            message: "Email is missing"
        },
    },
    optionalValue: "optional"
}
