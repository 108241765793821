import React, { useEffect, useState } from 'react';
import {fetchPartners} from '../../lib/dataAccess/services/userAccount'
import {fetchReportAllPartners, fetchAdminReportByPartner} from '../../lib/dataAccess/services/report'
import { ReportTable } from '../../components/reports/ReportTable'
import Header from '../../components/Header';
import { countryInput, filterMonthInput, filterYearInput, filterTypeInput } from "../../components/reports/inputs"
import {FormContainer, FormSection, FormRow, FormItem} from "../../components/layout/form";

export default function Report()  {

    const [partners, setPartners] = useState([]);
    const [partnerOptions, setPartnerOptions] = useState([]);
    const [orders, setOrders] = useState([]);
    const [invites, setInvites] = useState([]);
    const [numberOfOrders, setNumberOfOrders] = useState(0);
    const [numberOfInvites, setNumberOfInvites] = useState(0);
    const [totalKickback, setTotalKickback] = useState("");
    const [partner, setPartner] = useState("")
    const [country, setCountry] = useState("")
    const [filterMonth, setFilterMonth] = useState("")
    const [filterYear, setFilterYear] = useState("")
    const [filterType, setFilterType] = useState("")

    useEffect(() => {
        if(!partners || partners.length <= 0){

            fetchPartners().then((users) => {
                setPartners(users);
                setPartnerOptions(getPartnerOptions(users))
            });
        }

        /*console.log("in hook: " + "partner: " + partner + " country: " + country + " filterMonth: " + filterMonth +
            " filterYear: " + filterYear + " filterType: " + filterType)*/
        if(country && !partner){
            fetchReportAllPartners(country, filterMonth, filterYear, filterType).then((result) => {
                handleReportResult(result)
            });
        } else if(partner) {
            fetchAdminReportByPartner(partner, filterMonth, filterYear, filterType).then((result) => {
                handleReportResult(result)
            });
        }
    },[partners, partner, country, filterMonth, filterYear, filterType])


function handleReportResult(result){
    let customersWithOrder = [];
    let customersWithoutOrder = [];
    let customersWithOrderCount = 0;
    let customersWithoutOrderCount = 0;
    let totalKickbackValue = 0;
    let totalKickbackCurrency = "";

    console.log("result: " +  result + "\n");

    for(let i in result){
        console.log("entry - i: " +  i + "\n");
        for(let j in result[i].customersWithOrder){
            console.log("YYY entry - j (customerWithOrder: " +  j + "\n");
            customersWithOrder.push(result[i].customersWithOrder[j]);
            customersWithOrderCount++
            totalKickbackValue += result[i].totalKickbackValue
            totalKickbackCurrency = result[i].totalKickbackCurrency ? result[i].totalKickbackCurrency : totalKickbackCurrency
        }
        for(let j in result[i].customersWithoutOrder){
            console.log("XXX entry - j (customerWithOutOrder: " +  j + "\n");
            customersWithoutOrder.push(result[i].customersWithoutOrder[j]);
            customersWithoutOrderCount++
        }
    }

    setOrders(customersWithOrder);
    setInvites(customersWithoutOrder)
    setNumberOfInvites(customersWithoutOrderCount)
    setNumberOfOrders(customersWithOrderCount)
    setTotalKickback(totalKickbackValue > 0 ? totalKickbackValue + " "+ totalKickbackCurrency :"")
}

    function savePartner(event) {
        setPartner(event.target.value)
        setCountry("")
    }
    function saveCountry(event) {
        setCountry(event.target.value)
        setPartner("")
    }
    function saveFilterMonth(event) {
        setFilterMonth(event.target.value)
    }
    function saveFilterYear(event) {
        setFilterYear(event.target.value)
    }
    function saveFilterType(event) {
        setFilterType(event.target.value)
    }

    function getPartnerOptions(users){
        let options = []
        for(let partner in users){
            options.push({value:users[partner].id, label: users[partner].id + " --- " + users[partner].name})
        }
        return options;
    }

    const partnerInput = {
        label: "Partner",
        name: "partner",
        placeholder: "Partner",
        type: "select",
        validators: [],
        width: "col",
        emptyOption: "-- Select Partner --",
        options: partnerOptions
    };

    return (
        <>
            <Header/>
            <h2>Report</h2>
            <FormSection className="checkout_page_content">
            <FormContainer>
                    <FormRow>
                        <FormItem
                            item = {partnerInput}
                            saveValue = {savePartner}
                            currentValue= {partner}
                        />
                        <div className="form-container-div">OR</div>
                        <FormItem
                            item = {countryInput}
                            saveValue = {saveCountry}
                            currentValue= {country}
                        />
                    </FormRow>
                </FormContainer>
                <FormContainer>
                    <FormRow>
                        <FormItem
                            item = {filterMonthInput}
                            saveValue = {saveFilterMonth}
                        />
                        <FormItem
                            item = {filterYearInput()}
                            saveValue = {saveFilterYear}
                        />
                        <FormItem
                            item = {filterTypeInput}
                            saveValue = {saveFilterType}
                        />
                    </FormRow>
                </FormContainer>
            <div className="form-container">
                <h4>Invites ({numberOfInvites}):</h4>
                <ReportTable data={invites} showInvites={true}/>
            </div>
            <br/>
            <div className="form-container">
                <h4>Contracts: ({numberOfOrders})</h4>
                <ReportTable data={orders}/>
                <br/>
                <div style={{display: "none"}}>
                    Kickback Total: {totalKickback}
                </div>
            </div>
            </FormSection>
            <div className={"footer"}/>
        </>
    );

}