import { getSessionStorageItem, AUTH_TOKEN_SESSION_STORAGE, USER_INFO_SESSION_STORAGE, removeSessionStorageByName } from '../../lib/sessionStorage';
import { Auth} from 'aws-amplify';


export function getDataAccessHeaders(){
    return {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Accept-Language': '*',
        'Authorization': 'Bearer ' + getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE)
    }
}

export function getDataAccessHeadersWithToken(token){
    return {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Accept-Language': '*',
        'Authorization': 'Bearer ' + token
    }
}

export function getDataAccessHeadersNoAuth(){
    return {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Accept-Language': '*',
    }
}

export function logout(){
    removeSessionStorageByName(AUTH_TOKEN_SESSION_STORAGE);
    removeSessionStorageByName(USER_INFO_SESSION_STORAGE);
    Auth.signOut();
}