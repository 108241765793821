import React, {useEffect} from 'react';
import { getSessionStorageItem, AUTH_TOKEN_SESSION_STORAGE, USER_INFO_SESSION_STORAGE } from '../lib/sessionStorage';
import Header from '../components/Header';
import {Link} from 'react-router-dom';
import i18 from "../lib/i18n";

const t = i18.namespace("Home");

export default function Home()  {
    const userInfo = JSON.parse(getSessionStorageItem(USER_INFO_SESSION_STORAGE));
    const isAuthorized = getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE)
    useEffect(() =>{
        console.log("rerendered")
    }, [isAuthorized]);


    const guestContent = () =>{
      return <>
          <button type="button">
              <Link className="btn-primary order-1 order-md-2 btn"
                    to="/login">{t("login")}</Link>
          </button>
          <br/>
          {/*
          <div className="login-page_protection-plan">
              <Link
                  className="btn btn_transparent"
                  to="/register">
                  {t("register")}
              </Link>
          </div>
          */}
      </>
    }

    const restrictedContent = () =>{
      if(userInfo && userInfo.groups.find(group => group === "b2b-admin")){
        return adminContent();
      }
      if(userInfo){
        return partnerContent();
      }
      return <></>
    }

    const adminContent = () =>{
      return <>
          <button type="button">
              <Link className="btn-primary order-1 order-md-2 btn"
                    to="/admin/list">{t("admin.listUsers")}</Link>
          </button>
          <br/>
          <div className="login-page_protection-plan">
              <Link
                  className="btn btn_transparent"
                  to="/admin/report">
                  {t("admin.report")}
              </Link>
          </div>
      </>
    }

    const partnerContent = () =>{
      return <div className="login-page_protection-plan_partner">
          <Link
              className="btn btn_transparent"
              to="/partner">
              {t("partner.register")}
          </Link>
          <div className="login-page_protection-plan">
              <Link
                  className="btn btn_transparent"
                  to="/partner/report">
                  {t("partner.report")}
              </Link>
          </div>
      </div>
    }

    return <>
            <Header/>
            <div className="login-page">
                <div className="page_header container">
                    <h1 className="login-page_headline">{t("headline")}</h1>
                </div>

                <div className="login-page_wrapper container">
                    <div className="login-page_content">
                        <h3 className="login-page_subheadline">{t("subheadline")}</h3>

                        { isAuthorized ? restrictedContent() : guestContent() }
                    </div>
                </div>
            </div>
        <div className={"footer"} />
    </>

}