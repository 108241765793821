export function getStore(country) {
    switch (country.toLowerCase()) {
        case 'dk': return "a2w_demark";
        case 'da_dk': return 'a2w_sweden';
        case 'es': return "a2w_spain";
        case 'es_es': return 'a2w_spain';
        case 'ie': return "a2w_ireland";
        case 'en_ie':return 'a2w_ireland';
        case 'it': return "a2w_italy";
        case 'it_it': return 'a2w_italy';
        case 'se': return "a2w_sweden";
        case 'sv_se': return 'a2w_sweden';
        default: return "a2w_demark";
    }
}